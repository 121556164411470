import React, { useState, useEffect } from 'react';
import IconButton from '../common/IconButton';
import { MdEdit, MdDelete } from 'react-icons/md';
import Button from '../common/Button';
import AddProject from './AddProject';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProject, fetchProjects } from '../../redux/slices/projectSlice';
import { ToastContainer } from 'react-toastify';
import Utils from '../../utils/utils';
import { clearStatus } from '../../redux/slices/messageSlice';

const Projects = () => {

  const [displayProjectsModal, setDisplayProjectsModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const projectsData = useSelector(state => state.projects.projects);
  const successMessage = useSelector(state => state.message.successMessage);
  const errorMessage = useSelector(state => state.message.errorMessage);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  useEffect(() => {
    if (successMessage !== null) {
      Utils.showMessage(successMessage, true);
    } else if (errorMessage !== null) {
      Utils.showMessage(errorMessage);
    }
    dispatch(clearStatus());
  }, [successMessage, errorMessage, dispatch]);

  const handleProjectsModal = () => {
    setDisplayProjectsModal(!displayProjectsModal);
    if(selectedProject){
      setSelectedProject(null);
    }
  };

  const viewProject = (project) => {
    navigate("/projects/"+project.id);
  };

  const deleteProjectById = (id) => {
    dispatch(deleteProject(id));
  };

  const handleDelete = async (id) => {    
    Utils.showConfirmation(() => deleteProjectById(id), "Project");
  };

  const handleEditModal = (project) => {
    setSelectedProject(project);
    setDisplayProjectsModal(!displayProjectsModal);
  };
  
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="p-3">
        <Button isNormal={true} label="New Project" onClick={() => handleProjectsModal()}/>
      </div>
      <div className="bg-white p-4 rounded-sm border broder-gray-200 flex-1">
        <strong className="text-gray-700 font-medium">Projects</strong>
        <div className="border-x border-gray-200 rounded-sm mt-3 flex-1">
          <table className="w-full border border-gray-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Status</th>
                <th className="px-6 py-3 bg-gray-50">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                projectsData && projectsData.map((project, index) => (
                  <tr key={index} onClick={() => viewProject(project)} className="hover:bg-gray-50 cursor-pointer">
                    <td>{project.name}</td>
                    <td>{project.description}</td>
                    <td>{project.status}</td>
                    <td className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                      <IconButton icon={<MdEdit />} onClick={() => handleEditModal(project)} toolTipTitle="Edit project"/>
                      <IconButton icon={<MdDelete />} onClick={() => handleDelete(project.id)} toolTipTitle="Delete project"/>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      {displayProjectsModal && <AddProject selectedProject={selectedProject} isOpen={displayProjectsModal} onClose={(closing) => handleProjectsModal(closing)}/>}
    </>
  );
};

export default Projects;