import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Utils from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { addProjects, editProject } from '../../redux/slices/projectSlice';

const AddProject = ({ selectedProject, isOpen, onClose }) => {

    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    useEffect(() => {
        if(selectedProject){
            const { name, description } = selectedProject;
            setFormData({ name, description });
        };
    },[selectedProject]);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateFormFields = () => {
        const { name, description } = formData;
        
        if(name === ''){
            Utils.showMessage("Specify name");
            return false;
        }
        if(description === ''){
            Utils.showMessage("Specify description");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateFormFields()){
            if(selectedProject){
                const data = formData;
                data.id = selectedProject.id;
                dispatch(editProject({projectId: data.id, projectData: data}));
            }
            else{
                dispatch(addProjects(formData));
            }
            onClose(true);
        }
    };

    return (
        <Transition show={isOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="inline-block align-middle my-8 p-8 text-left bg-white rounded shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-6">
                        Project Form
                    </Dialog.Title>

                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                                Project Name
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="name"
                                type="text"
                                placeholder="Enter description"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                Description
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="description"
                                type="text"
                                placeholder="Enter description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center gap-2">
                            <button
                                className="bg-red-500 mr-2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                                onClick={() => onClose()}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddProject;
