import { configureStore } from '@reduxjs/toolkit';
import usersReducer from './slices/userSlice';
import savingsReducer from './slices/savingsSlice';
import expensesReducer from './slices/expensesSlice';
import authReducer from './slices/authSlice';
import paymentReducer from './slices/paymentSlice';
import debtReducer from './slices/debtSlice';
import dashboardReducer from './slices/dashboardSlice';
import messageReducer from './slices/messageSlice';
import financialProductReducer from './slices/financialProductSlice';
import projectsReducer from './slices/projectSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    savings: savingsReducer,
    expenses: expensesReducer,
    auth: authReducer,
    payments: paymentReducer,
    debts: debtReducer,
    dashboard: dashboardReducer,
    message: messageReducer,
    products: financialProductReducer,
    projects: projectsReducer,
  }
});

export default store;
