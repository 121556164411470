import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import Header from './Header'
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { logout } from '../../redux/slices/authSlice';

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  useEffect(() => {
    const checkTokenValidity = () => {
      const token = Cookies.get("token");
      if(!token){
        dispatch(logout());
        navigate("/login");
      }
    };

    checkTokenValidity();
    // every minute
    const intervalValidTimer = setInterval(checkTokenValidity, 60 * 1000);
    return () => clearInterval(intervalValidTimer);

  }, [dispatch, isLoggedIn, navigate]);

  return (
    <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 overflow-scroll">
        <Header />
        <div>
            { <Outlet />}
        </div>
      </div>
    </div>
  );
  
};

export default Layout;