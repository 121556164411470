import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../services/UserService';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await fetch('https://api.example.com/users');
  const data = await response.json();
  return data;
});

export const registerUser = createAsyncThunk('users/registerUser', async (user) => {
  const response = await UserService.registerUser(user);
  const data = await response.json();
  return data;
});

export const loginUser = createAsyncThunk('users/loginUser', async (user) => {
  const response = await UserService.loginUser(user);
  const data = await response.json();
  return data;
});

export const getSettingsDetails = createAsyncThunk('users/getSettingsDetails', async (user) => {
  const userId = Cookies.get('userId');
  const token = Cookies.get('token');
  const response = await fetch(Api.BASE_URL + '/users/settings', {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const closeAlert = createAsyncThunk('closeAlert', async (user) => {
  const response = await UserService.registerUser(user);
  const data = await response.json();

  return data;
});

export const updateInfo = createAsyncThunk('users/updateInfo', async ({ id, name, username }) => {
  const userId = Cookies.get('userId');
  const token = Cookies.get('token');
  const response = await fetch(Api.BASE_URL + '/users/settings/' + id, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify({ name, username }),
  });

  const data = await response.json();
  return data;
});

export const updatePassword = createAsyncThunk('users/updatePassword', async (password) => {
  const userId = Cookies.get('userId');
  const token = Cookies.get('token');
  const response = await fetch(Api.BASE_URL + '/users/password', {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(password),
  });

  const data = await response.json();
  return data;
});

export const updateDefaultCurrency = createAsyncThunk('users/currency', async (defaultCurrency) => {
  const userId = Cookies.get('userId');
  const token = Cookies.get('token');
  const response = await fetch(Api.BASE_URL + '/users/currency', {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(defaultCurrency),
  });

  const data = await response.json();
  return data;
});

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    user: null,
    userDetails: null,
    defaultCurrency: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.defaultCurrency = action.payload.defaultCurrency;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSettingsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSettingsDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(getSettingsDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(updateInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateDefaultCurrency.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDefaultCurrency.fulfilled, (state, action) => {
        state.loading = false;
        state.defaultCurrency = action.payload.defaultCurrency;
      })
      .addCase(updateDefaultCurrency.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

  },
});

export default usersSlice.reducer;
