import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
        const response = await fetch('/api/forgot-password', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            // Implement UI logic for a successful password reset request
            console.log('Password reset link sent successfully.');
        } else {
            // Handle error response (user not found, server error, etc.)
            console.error('Error sending password reset link.');
        }
        } catch (error) {
        console.error('Forgot password error:', error);
        }
    };

    return (
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
            <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email
                </label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    className="w-full p-2 border rounded-md"
                    placeholder="Your Email"
                    required
                />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                >
                    Reset Password
                </button>
            </form>
            <p className="mt-4 text-gray-600">
                <Link to="/login" className="text-blue-500 hover:underline">
                    Login here
                </Link>
            </p>
        </div>
    );
};

export default ForgotPassword;
