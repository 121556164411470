import React, { useState, useEffect } from 'react';
import IconButton from '../common/IconButton';
import { MdEdit, MdDelete } from 'react-icons/md';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteExpense, fetchExpenses, fetchExpensesWithPaging } from '../../redux/slices/expensesSlice';
import { ToastContainer } from 'react-toastify';
import Utils from '../../utils/utils';
import { clearStatus } from '../../redux/slices/messageSlice';
import AddExpense from './AddExpense';

const Expenses = () => {
  
  /**TODO:
   * Search Expense by
   * - date (year)
   * - Sort by
   * - By Payment type
   * - check https://courses.yogeshchavan.dev/build-expense-manager-app-using-react-and-typescript
   */

  const[currentPage, setCurrentPage] = useState(0);
  const[itemsPerPage] = useState(10);
  const [displayExpensesModal, setDisplayExpensesModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const expenseData = useSelector(state => state.expenses.expenses);
  const successMessage = useSelector(state => state.message.successMessage);
  const errorMessage = useSelector(state => state.message.errorMessage);

  useEffect(() => {
    dispatch(fetchExpensesWithPaging({pageCount: currentPage, pageSize: itemsPerPage}));
  }, [dispatch]);

  useEffect(() => {
    if (successMessage !== null) {
      Utils.showMessage(successMessage, true);
    } else if (errorMessage !== null) {
      Utils.showMessage(errorMessage);
    }
    dispatch(clearStatus());
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    reloadExpenseData();
  }, [currentPage]);

  const handleExpensesModal = (closing) => {
    setDisplayExpensesModal(!displayExpensesModal);
    if(selectedExpense){
      setSelectedExpense(null);
    }
  };

  const viewExpense = (expense) => {
    // navigate("/expense/"+expense.id);
  };

  const deleteExpenseById = (id) => {
    dispatch(deleteExpense(id));
  };

  const handleDelete = async (id) => {    
    Utils.showConfirmation(() => deleteExpenseById(id), "Expense");
  };

  const handleEditModal = (expense) => {
    setSelectedExpense(expense);
    setDisplayExpensesModal(!displayExpensesModal);
  };

  const reloadExpenseData = () => {
    dispatch(fetchExpensesWithPaging({pageCount: currentPage, pageSize: itemsPerPage}));
  }

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if(currentPage > 0){
      setCurrentPage(prevPage => prevPage - 1);
    }
  };
  
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="p-3">
        <Button isNormal={true} label="New Expense" onClick={() => handleExpensesModal()}/>
      </div>
      <div className="bg-white p-4 rounded-sm border broder-gray-200 flex-1">
        <strong className="text-gray-700 font-medium">Expenses</strong>
        <div className="border-x border-gray-200 rounded-sm mt-3 flex-1">
          <table className="w-full border border-gray-100">
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Amount</th>
                <th>Category</th>
                <th>Payment Method</th>
                <th>Location</th>
                <th className="px-6 py-3 bg-gray-50">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                expenseData.map((expense, index) => (
                  <tr key={index} onClick={() => viewExpense(expense)} className="hover:bg-gray-50 cursor-pointer">
                    <td>{Utils.displayDate(expense.date)}</td>
                    <td>{expense.description}</td>
                    <td>{expense.currency}{' '}{Utils.numberFormatter(expense.amount)}</td>
                    <td>{expense.category}</td>
                    <td>{expense.paymentMethod}</td>
                    <td>{expense.location}</td>
                    <td className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                      <IconButton icon={<MdEdit />} onClick={() => handleEditModal(expense)} toolTipTitle="Edit expense"/>
                      <IconButton icon={<MdDelete />} onClick={() => handleDelete(expense.id)} toolTipTitle="Delete expense"/>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between mt-4">
          {
            currentPage !== 0 && 
            <button onClick={handlePreviousPage} className="px-4 py-2 bg-gray-200 rounded">
              Previous
            </button>
          }
          {
            expenseData.length >= 10 && 
            <button onClick={handleNextPage} className="px-4 py-2 bg-gray-200 rounded">
              Next
            </button>
          }
        </div>
      </div>
      {displayExpensesModal && <AddExpense selectedExpense={selectedExpense} isOpen={displayExpensesModal} onClose={(closing) => handleExpensesModal(closing)}/>}
    </>
  );
};

export default Expenses;