import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Utils from '../../utils/utils';
import Api from '../../config/api_config';

const Registration = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    defaultCurrency: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let {name, email, password, defaultCurrency} = formData;
    if(name === '' || name === null){
      Utils.showMessage("Please provide your name");
      return;
    }
    if(email === '' || !Utils.isEmailValid(email)){
      Utils.showMessage("Please provide a valid email address");
      return;
    }
    if(defaultCurrency === ""){
      defaultCurrency = Utils.currencyOptions[0].value;
    }

    const username = email;

    fetch(Api.BASE_URL+'/users/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
      },
      body: JSON.stringify({name, username, password, defaultCurrency})
    })
    .then(response => response.json())
    .then(data => {
      Utils.showMessage("Registration successfull", true);
      setTimeout(() => {
        navigate("/login", {replace: true});
      },2000)
    })
    .catch(error => {
      Utils.showMessage(error);
      console.log(error)
    })

  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
      {/* <ToastContainer /> */}
      <div className="flex items-center justify-center flex-col mb-4">
        <h2 className="text-2xl font-semibold">Welcome to Kunneh</h2>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Full Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
            placeholder="Your Full Name"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
            placeholder="Your Email"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
              placeholder="Your Password"
              required
            />
            <button
              type="button"
              className="absolute right-0 top-0 mt-2 mr-2 text-gray-500"
              onClick={handleTogglePassword}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        {/* TODO: Add select default currency */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="currency">
            Default Currency
          </label>
          <select
            className="shadow bg-white w-full appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="defaultCurrency"
            name="defaultCurrency"
            value={formData.defaultCurrency}
            onChange={handleChange}
          >
            {/* <option value="">Select a Currency</option> */}
            {
                Utils.currencyOptions.map((category, index) => (
                    <option key={index} value={category.value}>{category.label}</option>        
                ))
            }
        </select>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-4 w-full rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          SIGN UP
        </button>
      </form>
      <p className="mt-4 text-gray-600 text-sm">
        Already registered?{' '}
        <Link to="/login" className="text-blue-500 underline">
          Sign in here
        </Link>
      </p>
    </div>
  );
};

export default Registration;
