import {
	HiOutlineViewGrid,
	HiOutlineCube,
	HiOutlineShoppingCart,
	HiOutlineUsers,
	HiOutlineDocumentText,
	HiOutlineAnnotation,
	HiOutlineQuestionMarkCircle,
	HiOutlineCog
} from 'react-icons/hi'

export const DASHBOARD_SIDEBAR_LINKS = [
	{
		key: 'dashboard',
		label: 'Dashboard',
		path: '/dashboard',
		icon: <HiOutlineViewGrid />
	},
	{
		key: 'savings',
		label: 'Savings',
		path: '/savings',
		icon: <HiOutlineCube />
	},
	{
		key: 'expenses',
		label: 'Expenses',
		path: '/expenses',
		icon: <HiOutlineShoppingCart />
	},
	{
		key: 'debts',
		label: 'Debts',
		path: '/debts',
		icon: <HiOutlineUsers />
	},
	{
		key: 'loans',
		label: 'Loans',
		path: '/loans',
		icon: <HiOutlineDocumentText />
	},
	{
		key: 'projects',
		label: 'Projects',
		path: '/projects',
		icon: <HiOutlineAnnotation />
	}
]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
	{
		key: 'settings',
		label: 'Settings',
		path: '/settings',
		icon: <HiOutlineCog />
	},
	// {
	// 	key: 'support',
	// 	label: 'Help & Support',
	// 	path: '/support',
	// 	icon: <HiOutlineQuestionMarkCircle />
	// }
]