import React from 'react';
import { FcDebt } from "react-icons/fc";
import { GiExpense } from "react-icons/gi";
import { FaBoxOpen } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import { FaPeopleArrows } from "react-icons/fa";
import { FaPeoplePulling } from "react-icons/fa6";
import { FaWallet } from "react-icons/fa";
import { MdMoney } from "react-icons/md";
import { useSelector } from 'react-redux';
import Cookies from "js-cookie";
import Utils from '../../utils/utils';


const DashboardStats = () => {

  const dashboardStatsData = useSelector(state => state.dashboard.dashboard);
  const _currency = Cookies.get("defaultCurrency");
  const selectedCurrency = Cookies.get("selectedCurrency");
  const currency = selectedCurrency ? selectedCurrency : _currency;

  return (
    <>
      <div className="flex gap-4 w-full">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-red-500">
            <GiExpense className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Expenses</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{currency !== "null" ? currency : ''} {dashboardStatsData && Utils.numberFormatter(dashboardStatsData.totalExpense)}</strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <FaWallet className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Savings</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{currency !== "null" ? currency : ''} {dashboardStatsData && Utils.numberFormatter(dashboardStatsData.totalSavings)}</strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-500">
            <FcDebt className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Total Debt</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{currency !== "null" ? currency : ''} {dashboardStatsData && Utils.numberFormatter(dashboardStatsData.totalDebt)}</strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-green-500">
            <MdMoney className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Total Loan</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{currency !== "null" ? currency : ''} {dashboardStatsData && Utils.numberFormatter(dashboardStatsData.totalLoan)}</strong>
            </div>
          </div>
        </BoxWrapper>
      </div>
      <div className="flex gap-4 w-full">
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-blue-500">
            <FaBoxOpen className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Savings Count</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{dashboardStatsData && Utils.numberFormatter(dashboardStatsData.savingsCount)}</strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-purple-500">
            <MdDone className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">Completed Savings</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{dashboardStatsData && Utils.numberFormatter(dashboardStatsData.completedSavingsCount)}</strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
            <FaPeoplePulling className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">People Owing</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{dashboardStatsData && Utils.numberFormatter(dashboardStatsData.debtCount)}</strong>
            </div>
          </div>
        </BoxWrapper>
        <BoxWrapper>
          <div className="rounded-full h-12 w-12 flex items-center justify-center bg-gray-500">
            <FaPeopleArrows className="text-2xl text-white"/>
          </div>
          <div className="pl-4">
            <span className="text-sm text-gray-500 font-light">People Owed</span>
            <div className="flex items-center">
              <strong className="text-xl text-gray-700 font-semibold">{dashboardStatsData && Utils.numberFormatter(dashboardStatsData.loanCount)}</strong>
            </div>
          </div>
        </BoxWrapper>
      </div>
    </>
  )
};

const BoxWrapper = ({ children }) => {
  return (
    <div className="bg-white rounded-sm p-4 flex flex-1 border-gray-200 items-center">
      {children}
    </div>
  );
};

export default DashboardStats