// SettingsPage.js
import React, { useState } from 'react';
import Utils from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { updateDefaultCurrency, updateInfo } from '../../redux/slices/userSlice';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { clearStatus } from '../../redux/slices/messageSlice';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/slices/authSlice';

const Settings = () => {
  
  const [defaultCurrency, setDefaultCurrency] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    username: ''
  });
  const [changed, setChanged] = useState(false);

  const _defaultCurrency = useSelector(state => state.users.defaultCurrency);
  const successMessage = useSelector(state => state.message.successMessage);
  const errorMessage = useSelector(state => state.message.errorMessage);
  const navigate =  useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    const curr = Cookies.get("defaultCurrency");
    if(curr){
      setDefaultCurrency(curr);
    }
    setFormData({name: Cookies.get("name"), username: Cookies.get("username")});
  }, []);
  

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });
  };

  const validateFormFields = () => {
    const {name, username } = formData;

    if(name === '' || name == null){
      Utils.showMessage("Specify name");
      return false;
    }
    if(username === '' || username == null){
      Utils.showMessage("Specify username");
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let changed = false;
    if(validateFormFields()){
      const {name, username} = formData;
      if(username !== Cookies.get("username")){
        changed = true;
      }
      dispatch(updateInfo({id: Cookies.get("userId"), name, username}))
    }
    if(changed){
      setChanged(changed);
    }
  };

  const clearCookies = () => {
    const cookies = Cookies.get();

    Object.keys(cookies).forEach(cookie => {
      Cookies.remove(cookie);
    });
    // navigate("/login");
  };

  const handleCurrencyChange = (e) => {
    Cookies.remove("defaultCurrency");
    setDefaultCurrency(e.target.value);
  };

  const validateCurrencyFormFields = () => {
    
    if(defaultCurrency === '' || defaultCurrency == null){
        Utils.showMessage("Specify defuault currency");
        return false;
    }
    return true;
  };

  const handleSubmitCurrency = (e) => {
    e.preventDefault();
    if(validateCurrencyFormFields()){
      Cookies.remove("defaultCurrency");
      dispatch(updateDefaultCurrency({defaultCurrency}));
      Cookies.set('defaultCurrency', defaultCurrency, { sameSite: 'strict', expires: 1, path: '/' });
    }
  };

  useEffect(() => {
    if (successMessage !== null) {
      Utils.showMessage(successMessage, true);
    } else if (errorMessage !== null) {
      Utils.showMessage(errorMessage);
    }
    dispatch(clearStatus());
    if(changed){
        clearCookies();
    }
  }, [successMessage, errorMessage, dispatch, changed, navigate]);

  return (
    <>
      <ToastContainer  autoClose={2000} />
      <div className="flex">
        <div className="container mx-auto p-2">
          <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">Update Info</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-600">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-600">Username</label>
                <input
                  type="email"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md"
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-gray-600">
                  Receive Notifications
                  <input
                    type="checkbox"
                    name="notification"
                    checked={formData.notification}
                    onChange={handleChange}
                    className="ml-2"
                  />
                </label>
              </div>
              <div className="mb-4">
                <label className="block text-gray-600">
                  Dark Mode
                  <input
                    type="checkbox"
                    name="darkMode"
                    checked={formData.darkMode}
                    onChange={handleChange}
                    className="ml-2"
                  />
                </label>
              </div> */}
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              >
                Update
              </button>
            </form>
          </div>
        </div>
        <div className="container mx-auto p-2">
          <div className="max-w-md mx-auto bg-white rounded p-8 shadow-md">
            <h2 className="text-2xl font-semibold mb-6">Default Currency</h2>
            <form onSubmit={handleSubmitCurrency}>
              <div className="mb-4">
                <select
                  className="shadow bg-white w-full appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="defaultCurrency"
                  name="defaultCurrency"
                  value={defaultCurrency}
                  onChange={handleCurrencyChange}
                >
                  <option value="">Select a Currency</option>
                  {
                    Utils.currencyOptions.map((category, index) => (
                      <option key={index} value={category.value}>{category.label}</option>        
                    ))
                  }
                </select>
              </div>
              
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
