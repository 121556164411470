import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useSelector } from 'react-redux';

const DashboardCharts = () => {
	const dashboardStatsData = useSelector(state => state.dashboard.dashboard);

	return (
	<div className="h-[22rem] bg-white p-4 rounded-sm border border-gray-200 flex flex-col">
		<strong className="text-gray-700 font-medium">Recent Transactions</strong>
		<div className="w-full ml-3 flex-1 text-xs">
			<ResponsiveContainer width="100%" height="100%">
				<BarChart
					width={500}
					height={300}
					data={dashboardStatsData && dashboardStatsData.chartData}
					margin={{
						top: 20,
						right: 10,
						left: -10,
						bottom: 0
					}}
				>
					<CartesianGrid strokeDasharray="3 3 0 0" vertical={false} />
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Legend />
					<Bar dataKey="savings" fill="#0aa5e9" />
					<Bar dataKey="expense" fill="#d35400" />
				</BarChart>
			</ResponsiveContainer>
		</div>
	</div>
	)
};

export default DashboardCharts;