import React from 'react';
import DashboardStats from './DashboardStats';
import DashboardCharts from './DashboardCharts';
import PeopleOwedByCurrency from './PeopleOwedByCurrency';
import RecentProjects from './RecentProjects';

const Dashboard = () =>{
  return (
    <div className="flex flex-col gap-4">
      <DashboardStats />
      <div className="flex w-full flex-row gap-4">
        <DashboardCharts />
        {/* <PeopleOwedByCurrency /> */}
      </div>
      <div className="flex w-full flex-row gap-4">
        <RecentProjects />
      </div>
    </div>
  )
};

export default Dashboard;