import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Utils from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { addFinancialProduct, editFinancialProduct } from '../../redux/slices/financialProductSlice';

const AddLoan = ({ selectedLoan, isOpen, onClose }) => {

    const [formData, setFormData] = useState({
        amount: '',
        lenderBorrower: '',
        currency: '',
        startDate: '',
        endDate: '',
        description: ''
    });

    useEffect(() => {
        if(selectedLoan){
            const { description, amount, lenderBorrower, currency, startDate, endDate } = selectedLoan;
            setFormData({ lenderBorrower, currency, amount, startDate, endDate, description });
        };
    },[selectedLoan]);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'amount' && value !== '' && !parseFloat(value)){
            Utils.showMessage("Only numbers allowed");
            return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateFormFields = () => {
        const { lenderBorrower, amount, currency, startDate } = formData;
        
        if(lenderBorrower === ''){
            Utils.showMessage("Specify Borrowed from name");
            return false;
        }
        if(currency === ''){
            Utils.showMessage("Specify currency");
            return false;
        }
        if(amount === ''){
            Utils.showMessage("Specify amount");
            return false;
        }
        if(startDate === ''){
            Utils.showMessage("Specify date");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateFormFields()){
            formData.type = "Loan";
            if(selectedLoan){
                const data = formData;
                data.id = selectedLoan.id;
                dispatch(editFinancialProduct({financialProductId: data.id, financialProductData: data}));
            }
            else{
                dispatch(addFinancialProduct(formData));
            }
            onClose(true);
        }
    };

    return (
        <Transition show={isOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="inline-block align-middle my-8 p-8 text-left bg-white rounded shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-6">
                        Loan Form
                    </Dialog.Title>

                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lenderBorrower">
                                Borrowed From
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="lenderBorrower"
                                type="text"
                                placeholder="Enter Lender Name"
                                name="lenderBorrower"
                                value={formData.lenderBorrower}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                                Currency
                            </label>
                            <select
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="currency"
                                name="currency"
                                value={formData.currency}
                                onChange={handleChange}
                            >
                                <option value="">Select a currency</option>
                                {
                                    Utils.currencyOptions.map((currency, index) => (
                                        <option key={index} value={currency.value}>{currency.label}</option>        
                                    ))
                                }
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                Amount
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="amount"
                                type="text"
                                placeholder="Enter target amount"
                                name="amount"
                                value={formData.amount}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startDate">
                                Date
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="startDate"
                                type="date"
                                placeholder="Select Start date"
                                name="startDate"
                                value={formData.startDate}
                                onChange={handleChange}
                            />
                        </div>
                        {
                            selectedLoan && 
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endDate">
                                    End Date
                                </label>
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="endDate"
                                    type="date"
                                    placeholder="Select End date"
                                    name="endDate"
                                    value={formData.endDate}
                                    onChange={handleChange}
                                />
                            </div>
                        }
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                                Description
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="description"
                                type="text"
                                placeholder="Enter description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="flex items-center gap-2s">
                            <button
                                className="bg-red-500 mr-2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                                onClick={() => onClose()}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddLoan;
