import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';
import { setErrorMessage } from './messageSlice';

export const fetchProjects = createAsyncThunk('projects/fetchProjects', async () => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/projects/all', {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const addProjects = createAsyncThunk('projects/addProject', async projectsData => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/projects', {
    method: 'POST',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(projectsData)
  });
  const data = await response.json();
  return data;
});

export const fetchProject = createAsyncThunk('projects/fetchProject', async (projectId) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/projects/${projectId}`, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  console.log({ data })
  return data;
});

export const editProject = createAsyncThunk('projects/editProject', async ({projectId, projectData}) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/projects/${projectId}`, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(projectData)
  });
  const data = await response.json();
  return data;
});

export const deleteProject = createAsyncThunk('projects/deleteProject', async projectId => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/projects/${projectId}`, {
    method: 'DELETE',
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: [],
    singleProject: null,
    status: 'idle',
    loading: false,
    error: null,
    success: null
  },
  reducers: {
    resetSuccess: (state) => {
      state.success = null;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.projects = action.payload;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addProjects.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(addProjects.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.loading = false;
        state.status = 'succeeded';
        state.projects.push(data);
      })
      .addCase(addProjects.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteProject.pending, state => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        const data = action.payload.data
        state.loading = false;
        state.status = 'succeeded';
        state.projects = state.projects.filter(project => project.id !== data.id);
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
        setErrorMessage(action.error.message);
      })
      .addCase(fetchProject.pending, state => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.singleProject = action.payload;
      })
      .addCase(fetchProject.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editProject.pending, state => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(editProject.fulfilled, (state, action) => {
        const data = action.payload.data
        state.loading = false;
        state.status = 'succeeded';
        state.projects = state.projects.map(project => project.id === data.id ? data : project);
      })
      .addCase(editProject.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.payload.error;
      });
  }
});

export const { resetSuccess, setSuccess } = projectsSlice.actions;
export default projectsSlice.reducer; // defined in extraReducer to update this state from other actions in other slices in the store
