import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';

export const fetchDashboardData = createAsyncThunk('dashboard/fetchDashboardData', async (currency) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/dashboard?currency='+currency, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    dashboard: null,
    status: 'idle',
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDashboardData.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.dashboard = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default dashboardSlice.reducer; // defined in extraReducer to update this state from other actions in other slices in the store
