import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/common/Layout';
import Savings from './components/savings/Savings';
import Dashboard from './components/dashboard/Dashboard';
import SavingDetails from './components/savings/SavingDetails';
import Registration from './components/user/Registration';
import Login from './components/user/Login';
import ForgotPassword from './components/user/ForgetPassword';
import Settings from './components/settings/Settings';
import Expenses from './components/expenses/Expenses';
import ExpenseDetails from './components/expenses/ExpenseDetails';
import Debts from './components/debts/Debts';
import DebtDetails from './components/debts/DebtDetails';
import Loans from './components/loans/Loans';
import LoanDetails from './components/loans/LoanDetails';
import Projects from './components/projects/Projects';
import ProjectDetails from './components/projects/ProjectDetails';
import LandingPage from './components/common/LandingPage';

const App = () =>{

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Registration />} />
        <Route exact path="/" element={<LandingPage />} />
        <Route path="*" element={<Layout />}>
          {/* <Route index element={<Dashboard />} /> */}
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="savings" element={<Savings />} />
          <Route path="expenses" element={<Expenses />} />
          <Route path="debts" element={<Debts />} />
          <Route path="loans" element={<Loans />} />
          <Route path="savings/:id" element={<SavingDetails />} />
          <Route path="expense/:id" element={<ExpenseDetails />} />
          <Route path="debts/:id" element={<DebtDetails />} />
          <Route path="loans/:id" element={<LoanDetails />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<ProjectDetails />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;

