import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';

export const fetchPayments = createAsyncThunk('payments/fetchPayments', async ({modelId, model}) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/payments/model/${modelId}?model=`+model, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const addPayment = createAsyncThunk('payments/addPayment', async (paymentsData) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/payments', {
    method: 'POST',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(paymentsData)
  });
  const data = await response.json();
  return data;
});

export const fetchPayment = createAsyncThunk('payments/fetchPayment', async (paymentId) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/payments/${paymentId}`, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const editPayment = createAsyncThunk('payments/editPayment', async ({paymentId, paymentData}) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/payments/${paymentId}`, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(paymentData)
  });
  const data = await response.json();
  return data;
});

export const deletePayment = createAsyncThunk('payments/deletePayment', async ({ paymentId, modelId, model}) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/payments/${paymentId}?model=${model}&modelId=${modelId}`, {
    method: 'DELETE',
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

const paymentSlice = createSlice({
  name: 'payments',
  initialState: {
    payments: [],
    status: 'idle',
    error: null,
    message: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchPayments.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.payments = action.payload;
      })
      .addCase(fetchPayments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addPayment.pending, state => {
        state.status = 'loading';
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        const payload = action.payload;
        const data = payload.data;
        state.loading = false;
        state.status = 'succeeded';
        if(payload.success){
          state.payments.push(data);
        }
      })
      .addCase(addPayment.rejected, (state, action) => {
        alert("hery")
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deletePayment.pending, state => {
        state.status = 'loading';
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        const payload = action.payload;
        const data = payload.data
        state.loading = false;
        state.status = 'succeeded';
        state.payments = state.payments.filter(payment => payment.id !== data.id);
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPayment.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchPayment.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.singlePayment = action.payload;
      })
      .addCase(fetchPayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editPayment.pending, state => {
        state.status = 'loading';
      })
      .addCase(editPayment.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const editedPayment = action.payload;
        state.payments = state.payments.map(payment => payment.id === editedPayment.id ? editedPayment : payment)
      })
      .addCase(editPayment.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

// export const { increment, decrement, reset } = paymentsSlice.actions; // actions that are defined in reducer: {} to handle this state
export default paymentSlice.reducer; // defined in extraReducer to update this state from other actions in other slices in the store
