import React, { Fragment, useState, useEffect } from 'react';
import { HiOutlineChatAlt, HiOutlineBell } from 'react-icons/hi';
import { Menu, Popover, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/slices/authSlice';
import Utils from '../../utils/utils';
import { fetchDashboardData } from '../../redux/slices/dashboardSlice';
import { FaUser } from 'react-icons/fa'

const Header = () =>{
    const [currency, setCurrency] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname !== "/"){
            removeDefaultCurrency();
        }
        const _defaultCurrency = Cookies.get("defaultCurrency");
        if(_defaultCurrency){
            setCurrency(_defaultCurrency);
            dispatch(fetchDashboardData(_defaultCurrency));
        }
    }, [dispatch, location.pathname]);

    const handleLogout = () => {
        navigate("/login");
        dispatch(logout());
        Cookies.remove("token");
        removeDefaultCurrency();
    };

    const removeDefaultCurrency = () => {
        Cookies.remove("selectedCurrency");
    };

    const handleCurrencyChange = (e) => {
        const selectCurrency = e.target.value;
        Cookies.set("selectedCurrency", selectCurrency);
        dispatch(fetchDashboardData(selectCurrency));
        setCurrency(selectCurrency);
    };

    return (
        <>
            <div className="bg-white h-16 mb-2 px-4 flex justify-between items-center border-b border-gray-200">
                <div className="relative">
                    {
                        location.pathname === "/dashboard" ? 
                        <select
                            className="shadow bg-white appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="currency"
                            name="currency"
                            value={currency}
                            onChange={handleCurrencyChange}
                        >
                        <option value="">Select a Currency</option>
                            {
                                Utils.currencyOptions.map((category, index) => (
                                    <option key={index} value={category.value}>{category.label}</option>        
                                ))
                            }
                        </select> : <></>
                    }
                </div>
                <div className="flex items-center gap-2 mr-2">
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                                <Popover.Button
                                    className={classNames(open && "bg-gray-100", "inline-flex items-center text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100 p-1.5 rounded-sm")}>
                                <HiOutlineChatAlt fontSize={24} />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                    >
                                    <Popover.Panel className="absolute right-0 z-10 mt-2.5 w-80">
                                        <div className="bg-white rounded-sm shadow-md ring-black ring-opacity-5 px-2 py-2.5">
                                                <strong className="text-grey-700 font-medium">Messages</strong>
                                                <div className="mt-2 py-1">
                                                    Test
                                                </div>
                                        </div>    
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                    <Popover className="relative">
                        {({ open }) => (
                            <>
                                <Popover.Button
                                    className={classNames(open && "bg-gray-100", "inline-flex items-center text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100 p-1.5 rounded-sm")}>
                                <HiOutlineBell fontSize={24} aria-label='notification' />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                    >
                                    <Popover.Panel className="absolute right-0 z-10 mt-2.5 w-80">
                                        <div className="bg-white rounded-sm shadow-md ring-black ring-opacity-5 px-2 py-2.5">
                                                <strong className="text-grey-700 font-medium">Notifications</strong>
                                                <div className="mt-2 py-1">
                                                    Test
                                                </div>
                                        </div>    
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                    <Menu as="div" className="relative inline-block text-left">
                        
                    <div>
                        <Menu.Button className="ml-2 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
                            <span className="sr-only">Open user menu</span>
                            <div className="h-10 w-10 rounded-full bg-gray-500 bg-cover bg-no-repeat bg-center flex items-center justify-center">
                                <FaUser className="text-white w-6 h-6" />
                            </div>
                        </Menu.Button>
                    </div>
                        <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {/* <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            onClick={() => navigate('/profile')}
                                            className={classNames(
                                                active && 'bg-gray-100',
                                                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                            )}
                                        >
                                            Your Profile
                                        </div>
                                    )}
                                </Menu.Item> */}
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            onClick={() => navigate('/settings')}
                                            className={classNames(
                                                active && 'bg-gray-100',
                                                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                            )}
                                        >
                                            Settings
                                        </div>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <div
                                            className={classNames(
                                                active && 'bg-gray-100',
                                                'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
                                            )}
                                            onClick={() => handleLogout()}
                                        >
                                            Sign out
                                        </div>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>
        </>
    );
}

export default Header;