import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Utils from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { addSavings, editSaving } from '../../redux/slices/savingsSlice';

const AddSaving = ({ selectedSaving, isOpen, onClose }) => {

    const [formData, setFormData] = useState({
        description: '',
        targetAmount: '',
        category: '',
        location: '',
        currency: '',
        targetDate: '',
    });

    useEffect(() => {
        if(selectedSaving){
            const { description, targetAmount, category, location, currency, targetDate } = selectedSaving;
            setFormData({ description, targetAmount, category, location, currency, targetDate });
        };
    },[selectedSaving]);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'targetAmount' && value !== '' && !parseFloat(value)){
            Utils.showMessage("Only numbers allowed");
            return;
        }
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validateFormFields = () => {
        const { description, targetAmount, category, currency, targetDate } = formData;
        
        if(description === ''){
            Utils.showMessage("Specify description");
            return false;
        }
        if(targetAmount === ''){
            Utils.showMessage("Specify target amount");
            return false;
        }
        if(category === ''){
            Utils.showMessage("Specify category");
            return false;
        }
        if(currency === ''){
            Utils.showMessage("Specify currency");
            return false;
        }
        if(targetDate === ''){
            Utils.showMessage("Specify target date");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateFormFields()){
            if(selectedSaving){
                const data = formData;
                data.id = selectedSaving.id;
                dispatch(editSaving({savingId: data.id, savingData: data}));
            }
            else{
                dispatch(addSavings(formData));
            }
            onClose();
        }
    };

    return (
        <Transition show={isOpen} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={onClose}
            >
                <div className="min-h-screen px-4 text-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

                <div className="inline-block align-middle my-8 p-8 text-left bg-white rounded shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-6">
                        Savings Form
                    </Dialog.Title>

                    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                        <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                            Description
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="description"
                            type="text"
                            placeholder="Enter description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                        </div>
                        <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                            Category
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="category"
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                        >
                            <option value="">Select a category</option>
                            {
                                Utils.categoryOptions.map((category, index) => (
                                    <option key={index} value={category.value}>{category.label}</option>        
                                ))
                            }
                        </select>
                        </div>
                        <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="category">
                            Currency
                        </label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="currency"
                            name="currency"
                            value={formData.currency}
                            onChange={handleChange}
                        >
                            <option value="">Select a currency</option>
                            {
                                Utils.currencyOptions.map((currency, index) => (
                                    <option key={index} value={currency.value}>{currency.label}</option>        
                                ))
                            }
                        </select>
                        </div>
                        <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                            Target Amount
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="targetAmount"
                            type="text"
                            placeholder="Enter target amount"
                            name="targetAmount"
                            value={formData.targetAmount}
                            onChange={handleChange}
                        />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="targetDate">
                            Target Date
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="targetDate"
                                type="date"
                                placeholder="Select target date"
                                name="targetDate"
                                value={formData.targetDate}
                                onChange={handleChange}
                            />
                        </div>

                        
                        <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="description">
                            Location
                        </label>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="location"
                            type="text"
                            placeholder="Enter location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                        />
                        </div>
                        <div className="flex items-center gap-2s">
                            <button
                                className="bg-red-500 mr-2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
                                onClick={() => onClose()}
                            >
                                Cancel
                            </button>
                            <button
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddSaving;
