import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjects } from '../../redux/slices/projectSlice';

function RecentProjects() {
    const projectsData = useSelector(state => state.projects.projects);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchProjects());
    },[dispatch]);

    return (
        <div className="bg-white p-4 rounded-sm border broder-gray-200 flex-1">
            <strong className="text-gray-700 font-medium">Projects</strong>
            <div className="border-x border-gray-200 rounded-sm mt-3 flex-1">
                <table className="w-full border border-gray-100">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            projectsData && projectsData.map((project, index) => (
                                <tr key={index}>
                                    <td>{project.name}</td>
                                    <td>{project.description}</td>
                                    <td>{project.status}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RecentProjects