import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { FaUserCircle, FaEye, FaEyeSlash } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Api from '../../config/api_config';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/slices/authSlice';
import { ToastContainer } from 'react-toastify';
import Utils from '../../utils/utils';
import { clearStatus } from '../../redux/slices/messageSlice';

const Login = () => {
  
  const errorMessage = useSelector(state => state.message.errorMessage);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (errorMessage !== null) {
      Utils.showMessage(errorMessage);
    }
    dispatch(clearStatus());
  }, [errorMessage, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {...formData, username: formData.email};
    fetch(Api.BASE_URL+"/authenticate/login", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
      const {token, name, username, defaultCurrency, userId, success, message} = data;
      if(success){
        dispatch(login(token));

        let currentTime = new Date();
        // let expirationTime = new Date(currentTime.getTime() + 2 * 60 * 1000); // 2 minutes
        let expirationTime = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);

        Cookies.set('token', token, { sameSite: 'strict', expires: expirationTime, path: '/' });
        Cookies.set('name', name, { sameSite: 'strict', expires: expirationTime, path: '/' });
        Cookies.set('username', username, { sameSite: 'strict', expires: expirationTime, path: '/' });
        Cookies.set('email', username, { sameSite: 'strict', expires: expirationTime, path: '/' });
        Cookies.set('userId', userId, { sameSite: 'strict', expires: expirationTime, path: '/' });
        Cookies.set('defaultCurrency', defaultCurrency, { sameSite: 'strict', expires: expirationTime, path: '/' });

        navigate('/dashboard', {replace: true});
      }
      else{
        Utils.showMessage(message);
      }
    })
    .catch(error => {
      Utils.showMessage(error);
    })
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded shadow-md">
      <ToastContainer autoClose={1000} />
      <div className="flex items-center justify-center flex-col mb-4">
        <FaUserCircle className="text-4xl text-blue-500 mb-2" />
        <h2 className="text-2xl font-semibold">Welcome Back!</h2>
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full p-2 border rounded-md"
            placeholder="Your Email"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
            Password
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="w-full p-2 border rounded-md"
              placeholder="Your Password"
              required
            />
            <button
              type="button"
              className="absolute right-0 top-0 mt-2 mr-2 text-gray-500"
              onClick={handleTogglePassword}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white w-full py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
        >
          SIGN IN
        </button>
        <p className="mt-4 text-gray-600 text-sm">
          Forgot password?{' '}
          <Link to="/forgot-password" className="text-blue-500 hover:underline">
            Forgot password?
          </Link>
        </p>
      </form>
      <p className="mt-2 text-gray-600 text-sm">
        Don't have an account?{' '}
        <Link to="/register" className="text-blue-500 hover:underline">
          Sign up here
        </Link>
      </p>
    </div>
  );
};

export default Login;
