import React from 'react';

const Button = ({ icon, onClick, label, isNormal }) =>{
    let classStyle = isNormal ? "flex items-center p-2 rounded-md bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800" :
    "flex items-center p-2 rounded-md bg-red-500 text-white hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800";
    
    return (
        <button className={classStyle} onClick={onClick}>
            {icon && icon}
            {label && <span>{label}</span>}
        </button>
    );
};

export default Button;