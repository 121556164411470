import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSaving } from '../../redux/slices/savingsSlice';
import Payment from '../payment/Payment';
import Utils from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import { clearStatus } from '../../redux/slices/messageSlice';
import { deletePayment, fetchPayments } from '../../redux/slices/paymentSlice';

const SavingDetails = () => {
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showPayButton, setShowPayButton] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const data = useSelector(state => state.savings.singleSaving);
    const paymentsData = useSelector((state) => state.payments.payments);
    const successMessage = useSelector(state => state.message.successMessage);
    const errorMessage = useSelector(state => state.message.errorMessage);

    useEffect(() =>  {
        const id = params.id;
        dispatch(fetchSaving(id));
        dispatch(fetchPayments({ modelId: id, model: 'savings' }));
    }, [dispatch, params.id]);

    useEffect(() => {
        handlePayButton(data);
    }, [data]);

    useEffect(() => {
        if (successMessage !== null) {
            Utils.showMessage(successMessage, true);
            dispatch(clearStatus());
            dispatch(fetchSaving(params.id));
        
        } else if (errorMessage !== null) {
            Utils.showMessage(errorMessage);
            dispatch(clearStatus());
        }
    }, [successMessage, errorMessage, dispatch, data, params]);

    const handleGoBack = () => {
        navigate("/savings");
    };

    const showPayment = () => {
        setShowPaymentForm(!showPaymentForm);
        handlePayButton(data);
    };

    const handleDeletePayment = (id) => {
        dispatch(deletePayment({ paymentId: id, modelId: params.id, model: 'savings' }));        
    };

    const handleDelete = async (payment) => {    
        Utils.showConfirmation(() => handleDeletePayment(payment.id), "Payment");
        handlePayButton(data, payment);
    };

    const handlePayButton = (data, payment = null) => {
        if(data){
            const _payments = payment ? paymentsData.filter(item => item.id !== payment.id) : paymentsData;
            const totalPayment = _payments.reduce((sum, payment) => sum + payment.amount, 0);
            const amount = data.targetAmount;
            const active = (amount - totalPayment) !== 0;
            setShowPayButton(active);
        }
    };

    return (
        <>
            <ToastContainer autoClose={2000} />
            <div className="flex items-center ml-3 mt-4">
                <div>
                    <button
                        className="bg-red-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={() => handleGoBack()}
                    >
                        Go Back
                    </button>
                    {
                        !showPayButton ? null :
                        <button
                            className="py-2 px-4 rounded hover:text-white hover:bg-sky-500 focus:outline-none focus:shadow-outline ml-2 border border-gray-500"
                            onClick={() => showPayment()}
                        >
                            Add Installment
                        </button>
                    }
                </div>
            </div>
            <div className="p-4 flex-1 mt-3 bg-white">
                <div className="bg-white p-4 mb-8">
                    <h2 className="text-medium mb-4">Saving Details</h2>
                    <div className="grid grid-cols-2 gap-4">
                        <div className="mb-4">
                            <strong>Description:</strong> {data && data.description}
                        </div>
                        <div className="mb-4">
                            <strong>Current Amount:</strong> {data && data.currency}{' '}{data && Utils.numberFormatter(data.currentAmount)}
                        </div>
                        <div className="mb-4">
                            <strong>Category:</strong> {data && data.category}
                        </div>
                        <div className="mb-4">
                            <strong>Target Date:</strong> {data && Utils.displayDate(data.targetDate)}
                        </div>
                        <div className="mb-4">
                            <strong>Target Amount:</strong> {data && data.currency}{' '}{data && Utils.numberFormatter(data.targetAmount)} 
                        </div>
                        <div className="mb-4">
                            <strong>Location:</strong> {data && data.location}
                        </div>
                        <div className="mb-4">
                            <strong>Balance:</strong> {data && data.currency}{' '}{data && (Utils.numberFormatter(data.targetAmount - data.currentAmount))} 
                        </div>
                    </div>
                </div>

                <div className="bg-white flex-1 my-8">
                    <strong className="text-gray-700 font-bold flex justify-center mb-2">Installments</strong>
                    <table className="w-full border border-gray-300">
                    <thead className="bg-gray-200">
                        <tr className="item-center">
                        <th className="border border-gray-300 px-4 py-2 text-center">Date</th>
                        <th className="border border-gray-300 px-4 py-2 text-center">Amount</th>
                        <th className="border border-gray-300 px-4 py-2 text-center">Note</th>
                        <th className="border border-gray-300 px-4 py-2 text-center">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentsData.map((payment) => (
                        <tr key={payment.id} className="hover:bg-gray-50 cursor-pointer">
                            <td className="border border-gray-300 px-4 py-2 text-center">{Utils.displayDate(payment.date)}</td>
                            <td className="border border-gray-300 px-4 py-2 text-center">{Utils.numberFormatter(payment.amount)}</td>
                            <td className="border border-gray-300 px-4 py-2 text-center">{payment.note}</td>
                            <td className="border border-gray-300 px-4 py-2 text-center">
                            <button
                                className="hover:bg-red-100 text-semibold text-red-500 py-1 px-2 border border-red-500 rounded focus:outline-none focus:shadow-outline"
                                onClick={() => handleDelete(payment)}
                            >
                                Delete
                            </button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
            {showPaymentForm && <Payment modelData={data} isOpen={showPaymentForm} onClose={() => showPayment()} type={"saving"}/>}
        </>
    );
};

export default SavingDetails;
