import Api from "../config/api_config";

export default class UserService {

  static registerUser(user) {
    return fetch(Api.BASE_URL+'/users/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      });  
    }
}
