import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';

export const fetchDebts = createAsyncThunk('debts/fetchDebts', async () => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/debts/debts',{
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const addDebt = createAsyncThunk('debts/addDebt', async debt => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId')
  const response = await fetch(Api.BASE_URL+'/debts', {
    method: 'POST',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(debt)
  });
  const data = await response.json();
  return data;
});

export const fetchDebt = createAsyncThunk('debts/fetchDebt', async (debtId) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/debts/${debtId}`, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});


export const deleteDebt = createAsyncThunk('debts/deleteDebt', async debtId => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/debts/${debtId}`, {
    method: 'DELETE',
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const editDebt = createAsyncThunk('debts/editDebt', async ({debtId, debtData }) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId')
  const response = await fetch(Api.BASE_URL+'/debts/'+debtId, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(debtData)
  });
  const data = await response.json();
  return data;
});

const debtSlice = createSlice({
  name: 'debts',
  initialState: {
    debts: [],
    singleDebt: null,
    loading: false,
    status: 'idle',
    error: null,
    success: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDebts.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchDebts.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.debts = action.payload;
      })
      .addCase(fetchDebts.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addDebt.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(addDebt.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.message ? payload.message : payload.success;
        const data = payload.data;
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        state.debts.push(data);
      })
      .addCase(addDebt.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchDebt.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchDebt.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.singleDebt = action.payload;
      })
      .addCase(fetchDebt.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editDebt.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(editDebt.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.mesage ? payload.message : payload.success;
        const data = payload.data;
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        state.debts = state.debts.map(debt => debt.id === data.id ? data : debt);
      })
      .addCase(editDebt.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteDebt.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(deleteDebt.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.mesage ? payload.message : payload.success;
        const data = payload.data
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        state.debts = state.debts.filter(debt => debt.id !== data.id);
      })
      .addCase(deleteDebt.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default debtSlice.reducer;
