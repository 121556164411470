import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';

export const fetchExpenses = createAsyncThunk('expenses/fetchExpenses', async () => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/expenses/all',{
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const fetchExpensesWithPaging = createAsyncThunk('expenses/fetchExpensesWithPaging', async ({pageCount, pageSize}) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/expenses/page/all?pageCount=${pageCount}&pageSize=${pageSize}`,{
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const addExpense = createAsyncThunk('expenses/addExpense', async expense => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/expenses', {
    method: 'POST',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(expense)
  });
  const data = await response.json();
  return data;
});

export const deleteExpense = createAsyncThunk('expenses/deleteExpense', async expenseId => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/expenses/${expenseId}`, {
    method: 'DELETE',
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const editExpense = createAsyncThunk('expenses/editExpense', async ({expenseId, expenseData }) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/expenses/'+expenseId, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(expenseData)
  });
  const data = await response.json();
  console.log({ data })
  return data;
});

const expensesSlice = createSlice({
  name: 'expenses',
  initialState: {
    expenses: [],
    loading: false,
    status: 'idle',
    error: null,
    success: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchExpensesWithPaging.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchExpensesWithPaging.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.expenses = action.payload;
      })
      .addCase(fetchExpensesWithPaging.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addExpense.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(addExpense.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.message ? payload.message : payload.success;
        const data = payload.data;
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        state.expenses.push(data);
      })
      .addCase(addExpense.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editExpense.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(editExpense.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.mesage ? payload.message : payload.success;
        const data = payload.data;
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        state.expenses = state.expenses.map(expense => expense.id === data.id ? data : expense);
      })
      .addCase(editExpense.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteExpense.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(deleteExpense.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.mesage ? payload.message : payload.success;
        const data = payload.data
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        state.expenses = state.expenses.filter(expense => expense.id !== data.id);
      })
      .addCase(deleteExpense.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default expensesSlice.reducer;
