import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';

export const fetchFinancialProducts = createAsyncThunk('products/fetchFinancialProducts', async (model) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/products/${model}`,{
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const addFinancialProduct = createAsyncThunk('products/addFinancialProduct', async loan => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId')
  const response = await fetch(Api.BASE_URL+'/products', {
    method: 'POST',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(loan)
  });
  const data = await response.json();
  return data;
});

export const fetchFinancialProduct = createAsyncThunk('products/fetchFinancialProduct', async (financialProductId) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/products/${financialProductId}`, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});


export const deleteFinancialProduct = createAsyncThunk('products/deleteFinancialProduct', async financialProductId => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/products/product/${financialProductId}`, {
    method: 'DELETE',
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const editFinancialProduct = createAsyncThunk('products/editFinancialProduct', async ({financialProductId, financialProductData }) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId')
  const response = await fetch(Api.BASE_URL+'/products/product/'+financialProductId, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(financialProductData)
  });
  const data = await response.json();
  return data;
});

const financialProductSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    singleFinancialProduct: null,
    loading: false,
    status: 'idle',
    error: null,
    success: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchFinancialProducts.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchFinancialProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.products = action.payload;
      })
      .addCase(fetchFinancialProducts.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addFinancialProduct.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(addFinancialProduct.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.message ? payload.message : payload.success;
        const data = payload.data;
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        if(data)
            state.products.push(data);
      })
      .addCase(addFinancialProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFinancialProduct.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchFinancialProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.singleFinancialProduct = action.payload;
      })
      .addCase(fetchFinancialProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editFinancialProduct.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(editFinancialProduct.fulfilled, (state, action) => {
        const payload = action.payload;
        const msg = payload.mesage ? payload.message : payload.success;
        const data = payload.data;
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        if(data)
            state.products = state.products.map(loan => loan.id === data.id ? data : loan);
      })
      .addCase(editFinancialProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteFinancialProduct.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(deleteFinancialProduct.fulfilled, (state, action) => {
        const payload = action.payload;
        console.log({payload})
        const msg = payload.mesage ? payload.message : payload.success;
        const data = payload.data
        state.success = msg;
        state.loading = false;
        state.status = 'succeeded';
        if(data)
            state.products = state.products.filter(loan => loan.id !== data.id);
      })
      .addCase(deleteFinancialProduct.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      });
  }
});

export default financialProductSlice.reducer;
