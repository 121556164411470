import React, { useState, useEffect } from 'react';
import IconButton from '../common/IconButton';
import { MdEdit, MdDelete } from 'react-icons/md';
import Button from '../common/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Utils from '../../utils/utils';
import { clearStatus } from '../../redux/slices/messageSlice';
import AddDebt from './AddDebt';
import { deleteFinancialProduct, fetchFinancialProducts } from '../../redux/slices/financialProductSlice';

const Debts = () => {

  const [displayDebtsModal, setDisplayDebtsModal] = useState(false);
  const [selectedDebt, setSelectedDebt] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const debtData = useSelector(state => state.products.products);
  const successMessage = useSelector(state => state.message.successMessage);
  const errorMessage = useSelector(state => state.message.errorMessage);

  useEffect(() => {
    dispatch(fetchFinancialProducts("debts"))
  }, [dispatch]);

  useEffect(() => {
    if (successMessage !== null) {
      Utils.showMessage(successMessage, true);
    } else if (errorMessage !== null) {
      Utils.showMessage(errorMessage);
    }
    dispatch(clearStatus());
  }, [successMessage, errorMessage, dispatch]);

  const handleDebtsModal = () => {
    setDisplayDebtsModal(!displayDebtsModal);
    if(selectedDebt){
      setSelectedDebt(null);
    }
  };

  const viewSaving = (debt) => {
    navigate("/debts/"+debt.id);
  };

  const deleteDebtById = (id) => {
    dispatch(deleteFinancialProduct(id))
  };

  const handleDelete = async (id) => {    
    Utils.showConfirmation(() => deleteDebtById(id), "Debt");
  };

  const handleEditModal = (debt) => {
    setSelectedDebt(debt);
    setDisplayDebtsModal(!displayDebtsModal);
  };
  
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="p-3">
        <Button isNormal={true} label="New Debt" onClick={() => handleDebtsModal()}/>
      </div>
      <div className="bg-white p-4 rounded-sm border broder-gray-200 flex-1">
        <strong className="text-gray-700 font-medium">Debts</strong>
        <div className="border-x border-gray-200 rounded-sm mt-3 flex-1">
          <table className="w-full border border-gray-100">
            <thead>
              <tr>
                <th>Date</th>
                {/* <th>End Date</th> */}
                <th>Borrowed From</th>
                <th>Currency</th>
                <th>Amount</th>
                <th>Balance</th>
                <th>Status</th>
                <th className="px-6 py-3 bg-gray-50">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                debtData.length > 0 && debtData.map((debt, index) => (
                  <tr key={index} onClick={() => viewSaving(debt)} className="hover:bg-gray-50 cursor-pointer">
                    <td>{Utils.displayDate(debt.startDate)}</td>
                    {/* <td>{Utils.displayDate(debt.endDate)}</td> */}
                    <td>{debt.lenderBorrower}</td>
                    <td>{debt.currency}</td>
                    <td>{Utils.numberFormatter(debt.amount)}</td>
                    <td>{Utils.numberFormatter(debt.balance)}</td>
                    <td>{debt.status}</td>
                    <td className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                      <IconButton icon={<MdEdit />} onClick={() => handleEditModal(debt)} toolTipTitle="Edit debt"/>
                      <IconButton icon={<MdDelete />} onClick={() => handleDelete(debt.id)} toolTipTitle="Delete debt"/>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      {displayDebtsModal && <AddDebt selectedDebt={selectedDebt} isOpen={displayDebtsModal} onClose={() => handleDebtsModal()}/>}
    </>
  );
};

export default Debts;