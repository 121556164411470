import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../../redux/slices/projectSlice';
import Utils from '../../utils/utils';
import { ToastContainer } from 'react-toastify';
import { clearStatus } from '../../redux/slices/messageSlice';

const ProjectDetails = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const data = useSelector(state => state.projects.singleProject);
    const successMessage = useSelector(state => state.message.successMessage);
    const errorMessage = useSelector(state => state.message.errorMessage);

    useEffect(() =>  {
        const id = params.id;
        dispatch(fetchProject(id));
    }, [dispatch, params.id]);

    useEffect(() => {
        if (successMessage !== null) {
            Utils.showMessage(successMessage, true);
            dispatch(clearStatus());
            dispatch(fetchProject(params.id));
          
        } else if (errorMessage !== null) {
            Utils.showMessage(errorMessage);
            dispatch(clearStatus());
        }
    }, [successMessage, errorMessage, dispatch, data, params]);

    const handleGoBack = () => {
        navigate("/projects");
    };

    return (
      <>
        <ToastContainer autoClose={2000} />
        <div className="flex items-center ml-3 mt-4">
          <div>
            <button
              className="bg-red-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              onClick={() => handleGoBack()}
            >
              Go Back
            </button>
          </div>
        </div>
        <div className="p-4 flex-1 mt-3 bg-white">
            <div className="bg-white p-4 mb-8">
                <h2 className="text-medium mb-4">Project Details</h2>
                <div className="grid grid-cols-2 gap-4">
                    <div className="mb-4">
                        <strong>Name:</strong> {data && data.name}
                    </div>
                    <div className="mb-4">
                        <strong>Description:</strong> {data && data.description}
                    </div>
                    <div className="mb-4">
                        <strong>Status:</strong> {data && data.status}
                    </div>
                </div>
            </div>
        </div>
      </>
    );
};

export default ProjectDetails;
