import React, { useState, useEffect } from 'react';
import IconButton from '../common/IconButton';
import { MdEdit, MdDelete } from 'react-icons/md';
import Button from '../common/Button';
import AddSaving from './AddSaving';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSaving, fetchSavings } from '../../redux/slices/savingsSlice';
import { ToastContainer } from 'react-toastify';
import Utils from '../../utils/utils';
import { clearStatus } from '../../redux/slices/messageSlice';

const Savings = () => {

  const [displaySavingsModal, setDisplaySavingsModal] = useState(false);
  const [selectedSaving, setSelectedSaving] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const savingsData = useSelector(state => state.savings.savings);
  const successMessage = useSelector(state => state.message.successMessage);
  const errorMessage = useSelector(state => state.message.errorMessage);

  useEffect(() => {
    dispatch(fetchSavings());
  }, [dispatch]);

  useEffect(() => {
    if (successMessage !== null) {
      Utils.showMessage(successMessage, true);
    } else if (errorMessage !== null) {
      Utils.showMessage(errorMessage);
    }
    dispatch(clearStatus());
  }, [successMessage, errorMessage, dispatch]);

  const handleSavingsModal = () => {
    setDisplaySavingsModal(!displaySavingsModal);
    if(selectedSaving){
      setSelectedSaving(null);
    }
  };

  const viewSaving = (saving) => {
    navigate("/savings/"+saving.id);
  };

  const deleteSavingById = (id) => {
    dispatch(deleteSaving(id));
  };

  const handleDelete = async (id) => {    
    Utils.showConfirmation(() => deleteSavingById(id), "Saving");
  };

  const handleEditModal = (saving) => {
    setSelectedSaving(saving);
    setDisplaySavingsModal(!displaySavingsModal);
  };
  
  return (
    <>
      <ToastContainer autoClose={2000} />
      <div className="p-3">
        <Button isNormal={true} label="New Saving" onClick={() => handleSavingsModal()}/>
      </div>
      <div className="bg-white p-4 rounded-sm border broder-gray-200 flex-1">
        <strong className="text-gray-700 font-medium">Savings</strong>
        <div className="border-x border-gray-200 rounded-sm mt-3 flex-1">
          <table className="w-full border border-gray-100">
            <thead>
              <tr>
                <th>Target Date</th>
                <th>Description</th>
                <th>Location</th>
                <th>Currency</th>
                <th>Target Amount</th>
                <th>Current Amount</th>
                <th>Balance</th>
                <th className="px-6 py-3 bg-gray-50">Action</th>
              </tr>
            </thead>
            <tbody>
              {
                savingsData.map((saving, index) => (
                  <tr key={index} onClick={() => viewSaving(saving)} className="hover:bg-gray-50 cursor-pointer">
                    <td>{Utils.displayDate(saving.targetDate)}</td>
                    <td>{saving.description}</td>
                    <td>{saving.location}</td>
                    <td>{saving.currency}</td>
                    <td>{Utils.numberFormatter(saving.targetAmount)}</td>
                    <td>{Utils.numberFormatter(saving.currentAmount)}</td>
                    <td>{(Utils.numberFormatter(saving.targetAmount - saving.currentAmount))}</td>
                    <td className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                      <IconButton icon={<MdEdit />} onClick={() => handleEditModal(saving)} toolTipTitle="Edit saving"/>
                      <IconButton icon={<MdDelete />} onClick={() => handleDelete(saving.id)} toolTipTitle="Delete saving"/>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
      {displaySavingsModal && <AddSaving selectedSaving={selectedSaving} isOpen={displaySavingsModal} onClose={handleSavingsModal}/>}
    </>
  );
};

export default Savings;