import React from 'react';

const IconButton = ({ icon, onClick, label, toolTipTitle }) =>{
    const bgColor = toolTipTitle.indexOf("Edit") !== -1 ? "bg-sky-500" : "bg-red-500";
    const classStyle = "flex items-center p-2 rounded-md text-white focus:outline-none focus:shadow-outline-blue "+bgColor;
    
    return (
        <button 
            className={classStyle}
            onClick={onClick && onClick}
            title={toolTipTitle}
        >
            {icon}
            {/* {label && <span>{label}</span>} */}
        </button>
    );
};

export default IconButton;