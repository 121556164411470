import React from 'react';
import { BsBox2 } from "react-icons/bs";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD_SIDEBAR_BOTTOM_LINKS, DASHBOARD_SIDEBAR_LINKS } from './../../lib/consts/index';
import classNames from 'classnames';
import { HiOutlineLogout } from 'react-icons/hi';

const linkClass = 'flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base';

const Sidebar = () =>{
  const navigate = useNavigate();

  const homePage = () => {
    navigate("/dashboard");
  };

  return (
    <div className="flex flex-col bg-neutral-900 w-60 p-3 text-white">
      <div className="flex items-cneter gap-2 px-1 py-3" style={{cursor: "pointer"}} onClick={() => homePage()}>
        <BsBox2 fontSize={23}/>
        <span className="text-neutral-100 text-lg">Kunneh</span>
      </div>
      <div className="flex flex-col flex-1 py-8 gap-0.5">
        {
          DASHBOARD_SIDEBAR_LINKS.map((item) => (
            <SideBarLink key={item.key} item={item} />
          ))
        }
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        {
          DASHBOARD_SIDEBAR_BOTTOM_LINKS.map((item) => (
            <SideBarLink key={item.key} item={item} />
          )) 
        }
        <div className={classNames("text-red-900 cursor-pointer", linkClass)} onClick={() => navigate("/login")}>
          <span className="text-xl"><HiOutlineLogout /></span> 
          Logout
        </div>
      </div>
    </div>
  )
}

const SideBarLink = ({ item }) => {

  const { pathname } = useLocation();

  return (
    <Link to={item.path} className={classNames(pathname === item.path ? "bg-neutral-700 text-white" : "text-neutral-400", linkClass)}>
      <span>{item.icon}</span>
      {item.label}
    </Link>
  );
};

export default Sidebar;