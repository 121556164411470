import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api from '../../config/api_config';
import Cookies from 'js-cookie';
import Utils from '../../utils/utils';
import { useDispatch } from 'react-redux';
import { setSuccessMessage, setErrorMessage, clearStatus } from './messageSlice';

// const dispatch  = useDispatch();
export const fetchSavings = createAsyncThunk('savings/fetchSavings', async () => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/savings/all', {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const addSavings = createAsyncThunk('savings/addSaving', async savingsData => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+'/savings', {
    method: 'POST',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(savingsData)
  });
  const data = await response.json();
  return data;
});

export const fetchSaving = createAsyncThunk('savings/fetchSaving', async (savingId) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/savings/${savingId}`, {
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

export const editSaving = createAsyncThunk('savings/editSaving', async ({savingId, savingData}) => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/savings/${savingId}`, {
    method: 'PUT',
    headers: Utils.getHeaders(userId, token),
    body: JSON.stringify(savingData)
  });
  const data = await response.json();
  return data;
});

export const deleteSaving = createAsyncThunk('savings/deleteSaving', async savingId => {
  const token = Cookies.get('token');
  const userId = Cookies.get('userId');
  const response = await fetch(Api.BASE_URL+`/savings/${savingId}`, {
    method: 'DELETE',
    headers: Utils.getHeaders(userId, token),
  });
  const data = await response.json();
  return data;
});

const savingsSlice = createSlice({
  name: 'savings',
  initialState: {
    savings: [],
    singleSaving: null,
    status: 'idle',
    loading: false,
    error: null,
    success: null
  },
  reducers: {
    resetSuccess: (state) => {
      state.success = null;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSavings.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchSavings.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.savings = action.payload.data;
      })
      .addCase(fetchSavings.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addSavings.pending, state => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(addSavings.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.loading = false;
        state.status = 'succeeded';
        state.savings.push(data);
      })
      .addCase(addSavings.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(deleteSaving.pending, state => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(deleteSaving.fulfilled, (state, action) => {
        const data = action.payload.data
        state.loading = false;
        state.status = 'succeeded';
        state.savings = state.savings.filter(saving => saving.id !== data.id);
      })
      .addCase(deleteSaving.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
        setErrorMessage(action.error.message);
      })
      .addCase(fetchSaving.pending, state => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(fetchSaving.fulfilled, (state, action) => {
        state.loading = false;
        state.status = 'succeeded';
        state.singleSaving = action.payload;
      })
      .addCase(fetchSaving.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editSaving.pending, state => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(editSaving.fulfilled, (state, action) => {
        const data = action.payload.data
        state.loading = false;
        state.status = 'succeeded';
        state.savings = state.savings.map(saving => saving.id === data.id ? data : saving);
      })
      .addCase(editSaving.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.payload.error;
      });
  }
});

// export const { increment, decrement, reset } = savingsSlice.actions; // actions that are defined in reducer: {} to handle this state
export const { resetSuccess, setSuccess } = savingsSlice.actions;
export default savingsSlice.reducer; // defined in extraReducer to update this state from other actions in other slices in the store
