import { createSlice } from '@reduxjs/toolkit';
import { addSavings, deleteSaving, editSaving } from './savingsSlice';
import { addExpense, deleteExpense, editExpense } from './expensesSlice';
import { addDebt, deleteDebt, editDebt } from './debtSlice';
import { addPayment, deletePayment } from './paymentSlice';
import { registerUser, updateDefaultCurrency, updateInfo, updatePassword } from './userSlice';
import { addFinancialProduct, deleteFinancialProduct, editFinancialProduct } from './financialProductSlice';
import { addProjects, editProject } from './projectSlice';

const messageSlice = createSlice({
  name: 'message',
  initialState: {
    successMessage: null,
    errorMessage: null,
  },
  reducers: {
    setSuccessMessage: (state, action) => {
        state.successMessage = action.payload;
        state.errorMessage = null;
    },
    setErrorMessage: (state, action) => {
        state.errorMessage = action.payload;
        state.successMessage = null;
    },
    clearStatus: (state) => {
        state.successMessage = null;
        state.errorMessage = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(registerUser.fulfilled, (state, action) =>{
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(registerUser.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(addSavings.fulfilled, (state, action) =>{
      const payload = action.payload;
      if(payload.error !== null){
        state.errorMessage = payload.error
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(addSavings.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(deleteSaving.fulfilled, (state, action) =>{
      const payload = action.payload;
      console.log({ payload})
      if(payload.error){
        state.errorMessage = payload.error;
        console.log("=== false ====")
      }
      else{
        state.successMessage = payload.success;
        console.log("=== true ====")
      }
    })
    .addCase(deleteSaving.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(editSaving.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(editSaving.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(addExpense.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(addExpense.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(editExpense.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(editExpense.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(deleteExpense.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(deleteExpense.rejected, (state, action) =>{
      state.errorMessage = action.error.message;
    })
    .addCase(addDebt.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(addDebt.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(editDebt.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(editDebt.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(deleteDebt.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(deleteDebt.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(addProjects.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(addProjects.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(editProject.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(editProject.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(addPayment.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(addPayment.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(addFinancialProduct.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(addFinancialProduct.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(deleteFinancialProduct.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(deleteFinancialProduct.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(editFinancialProduct.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(editFinancialProduct.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(deletePayment.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(deletePayment.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(updateInfo.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(updateInfo.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(updatePassword.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(updatePassword.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    })
    .addCase(updateDefaultCurrency.fulfilled, (state, action) => {
      const payload = action.payload;
      if(payload.message){
        state.errorMessage = payload.message
      }
      else{
        state.successMessage = payload.success;
      }
    })
    .addCase(updateDefaultCurrency.rejected, (state, action) => {
      state.errorMessage = action.error.message;
    });
  }
});

// actions that are defined in reducer: {} to handle this state
export const { setSuccessMessage, setErrorMessage, clearStatus } = messageSlice.actions;
export default messageSlice.reducer; // defined in extraReducer to update this state from other actions in other slices in the store

