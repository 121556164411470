import React from 'react';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
    };

    const navigate = useNavigate();

    return (
        <div className="bg-gray-100 min-h-screen">
        {/* Navbar */}
            <nav className="bg-blue-500 p-4">
                <div className="container mx-auto flex justify-between items-center">
                <a href="/" className="flex items-center text-white font-bold" style={{ textDecoration: 'none' }}>
                    {/* <img src="path_to_your_logo" alt="Company Logo" className="h-8 mr-2" /> */}
                    <span>Kunneh</span>
                </a>
                <ul className="flex space-x-4">
                    <li><a href="/" className="text-white hover:text-blue-200" style={{ textDecoration: 'none' }}>Home</a></li>
                    <li><a className="text-white hover:text-blue-200" style={{ textDecoration: 'none' }} onClick={() => scrollToSection('about')}>About</a></li>
                    <li><a className="text-white hover:text-blue-200" style={{ textDecoration: 'none' }} onClick={() => scrollToSection('contact')}>Contact</a></li>
                    <li><a href="/login" className="text-white hover:text-blue-200" style={{ textDecoration: 'none' }}>Sign In</a></li> {/* Added Sign In link */}
                </ul>
                </div>
            </nav>

            {/* Hero Section */}
            <section className="py-16">
                <div className="container mx-auto text-center">
                <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-gray-800 mb-4">Welcome to Your Company</h1>
                <p className="text-lg sm:text-xl md:text-2xl text-gray-600 mb-8">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded" onClick={() => navigate("/register")}>Get Started</button>
                </div>
            </section>

                {/* About Section */}
                <section id="about" className="bg-gray-200 py-16">
                <div className="container mx-auto">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-8 text-center">About Us</h2>
                <p className="text-lg text-gray-600 mb-8 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</p>
                </div>
            </section>

            {/* Dashboard Section */}
            <section className="bg-white py-16">
                <div className="container mx-auto">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-8 text-center">Dashboard</h2>
                {/* Placeholder for dashboard graphs */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div className="bg-gray-300 p-4 rounded"></div>
                    <div className="bg-gray-300 p-4 rounded"></div>
                    <div className="bg-gray-300 p-4 rounded"></div>
                </div>
                </div>
            </section>

            {/* Savings, Debts, Expenses, and Projects Section */}
            <section className="py-16">
                <div className="container mx-auto">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-8 text-center">Manage Your Finances and Projects</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {/* Placeholder for savings list */}
                    <div className="bg-gray-300 p-4 rounded"></div>
                    {/* Placeholder for debts */}
                    <div className="bg-gray-300 p-4 rounded"></div>
                    {/* Placeholder for expenses */}
                    <div className="bg-gray-300 p-4 rounded"></div>
                    {/* Placeholder for projects */}
                    <div className="bg-gray-300 p-4 rounded"></div>
                </div>
                </div>
            </section>

                    {/* Contact Section */}
                    <section id="contact" className="py-16">
                <div className="container mx-auto">
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-800 mb-8 text-center">Contact Us</h2>
                <p className="text-lg text-gray-600 mb-8 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.</p>
                </div>
            </section>

            {/* Footer */}
            <footer className="bg-gray-900 text-white py-8">
                <div className="container mx-auto text-center">
                <p>&copy; 2024 Kunneh. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
